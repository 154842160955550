import { ContentfulLivePreview } from "@contentful/live-preview";
import type { Entry } from "contentful";

import Logger from "~/services/logger/logger";

import localeConfig from "../../locale.config";

const contentfulUtils = {
  localesMap: localeConfig.contentfulLocales,
  /**
   * Maps a Next.js/Vercel locale to the corresponding Contentful locale.
   *
   * Contentful does not allow custom locales, so we have some shared rules.
   * - All ME countries are grouped under AE.
   *
   * @param locale - The locale for the current request
   */
  mapLocale(locale: string | undefined) {
    if (!locale || !locale.includes("-")) {
      Logger.instance.error(`Invalid locale ${locale} found in request, will use default en-US`);
      return "en-US";
    }

    if (locale in this.localesMap) {
      return this.localesMap[locale as keyof typeof this.localesMap];
    }

    Logger.instance.warn(`Locale ${locale} is not mapped, will use it as-is.`);
    const [language, country] = locale.split("-");

    // Contentful only allows uppercase countries.
    // Even though this is a fallback, the country is assumed to be uppercase.
    // E.g. a path of /it-it/ will become it-IT
    return `${language}-${country.toUpperCase()}`;
  },

  /**
   * Check if string is wrapped in "%" symbols
   *
   * @param {string} highlightString - string to check
   *
   * @returns {boolean}
   */
  isHighlightText(highlightString: string) {
    return highlightString?.includes("%%");
  },

  /**
   * Check if entry comes from contentful
   *
   * @param {any} entry - entry to check
   *
   * @returns {boolean}
   */
  isContentful<T>(entry: any): entry is Entry<T> {
    return entry && entry.sys;
  },

  /**
   * Returns a hook to use Contentful's Inspector Mode specifying the entry once.
   *
   * @see https://www.contentful.com/developers/docs/tutorials/general/live-preview/#set-up-inspector-mode
   */
  useInspectorMode: (entry?: Entry<unknown>) => {
    if (!entry) {
      return undefined;
    }
    return {
      getProps: (fieldId: string) => {
        return ContentfulLivePreview.getProps({ entryId: entry.sys.id, fieldId });
      },
    };
  },
};

export default contentfulUtils;
